import z from 'zod';
import { scriptProviderSchema_v5 } from './script.v5.zod';
import { pageProviderSchema_v3 } from '../../v3/var-providers/page.v3.zod';
import { sliceProviderSchema_v3 } from '../../v3/var-providers/slice.v3.zod';
import { timerProviderSchema_v3 } from '../../v3/var-providers/timer.v3.zod';
import { pagedProviderSchema_v3 } from '../../v3/var-providers/paged.v3.zod';
import { lockingProviderSchema_v3 } from '../../v3/var-providers/locking.v3.zod';
import { processProviderSchema_v3 } from '../../v3/var-providers/process.v3.zod';
import { localProviderConfigSchema_v3 } from '../../v3/var-providers/local.v3.zod';
import { calendarProviderSchema_v3 } from '../../v3/var-providers/calendar.v3.zod';
import { formattedProviderSchema_v3 } from '../../v3/var-providers/formatted.v3.zod';
import { sparkplugProviderSchema_v3 } from '../../v3/var-providers/sparkplug.v3.zod';
import { macroVarpTargetSchema_v3 } from '../../v3/var-providers/macro-target.v3.zod';
import { varStatusProviderSchema_v3 } from '../../v3/var-providers/var-status.v3.zod';
import { materialIdProviderSchema_v3 } from '../../v3/var-providers/material-id.v3.zod';
import { comparativeProviderSchema_v3 } from '../../v3/var-providers/comparative.v3.zod';
import { createMaterialProviderSchema_v5 } from '../../v5/var-providers/create-material.v5.zod';
import { mqttPublishProviderConfigSchema_v3 } from '../../v3/var-providers/mqtt-publish.v3.zod';
import { mqttSubscribeProviderConfigSchema_v3 } from '../../v3/var-providers/mqtt-subscribe.v3.zod';
import { sparkplugDeviceWriteProviderSchema_v3 } from '../../v3/var-providers/sparkplug-device-write.v3.zod';
import { udpSendProviderSchema } from './udp-send.v5.zod';
import { udpReceiveProviderConfigSchema } from './udp-receive.v5.zod';
import { tcpClientProviderSchema } from './tcp-client.v5.zod';
import { tcpServerProviderSchema } from './tcp-server.v5.zod';

// TODO this is redundant with varpTypes_v5
export const varpSchema_v5 = z.discriminatedUnion('type', [
    pageProviderSchema_v3,
    pagedProviderSchema_v3,
    timerProviderSchema_v3,
    sliceProviderSchema_v3,
    scriptProviderSchema_v5,
    processProviderSchema_v3,
    macroVarpTargetSchema_v3,
    lockingProviderSchema_v3,
    calendarProviderSchema_v3,
    varStatusProviderSchema_v3,
    sparkplugProviderSchema_v3,
    formattedProviderSchema_v3,
    materialIdProviderSchema_v3,
    comparativeProviderSchema_v3,
    localProviderConfigSchema_v3,
    createMaterialProviderSchema_v5,
    mqttPublishProviderConfigSchema_v3,
    mqttSubscribeProviderConfigSchema_v3,
    sparkplugDeviceWriteProviderSchema_v3,
    udpSendProviderSchema,
    udpReceiveProviderConfigSchema,
    tcpClientProviderSchema,
    tcpServerProviderSchema,
]);
