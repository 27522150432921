/* eslint-disable max-lines */
/* eslint sort-keys: error */
import { messages } from 'src/i18n/i18n';
import { AppletDesignMenuItem } from '@redviking/argonaut-core-ui/src/applet/design/util/AppletDesignMenuItem';
import { AsyncComponent } from 'vue';
import { v4 as uuidV4 } from 'uuid';
import { varOrConstIsSet } from '../validations/var-or-const.validations';
import { MetricExpr, getMetricExpr } from '@redviking/argonaut-util/src/metric-parser/metric-expr';
import {
    validateSparkplugDeviceWriteMetricExpr,
    validateSparkplugMetricExpr,
} from '../validations/metric-expr.validations';
import { CausalError } from '@redviking/causal-error';
import { accessor } from 'src/store/store';
import { DeployParamKind } from '@redviking/argonaut-util/src/mes/deployParam.zod';
import { type Latest, varpTypes } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import { validateComparisons } from '@redviking/argonaut-core-ui/src/mes/lib/validate-comparison';
import { ZodError } from 'zod';
import { fromZodError } from 'zod-validation-error';

function wrapAndThrow (fnMaybeThrow: () => void, msg: string) {
    try {
        fnMaybeThrow();
    } catch (err) {
        console.error(err);
        if (err instanceof ZodError) {
            throw new CausalError(msg, {
                message: fromZodError(err).message,
                zodError: err,
            });
        }
        throw new CausalError(msg, err);
    }
}

export interface VarpTypeMapItem<V extends Latest.VarProviders.VarProvider = Latest.VarProviders.VarProvider> extends AppletDesignMenuItem<V['type']> {
    tab?: AsyncComponent;
    tree?: AsyncComponent;
    /** if defined, this should throw if there's an error */
    validator: ((vp: V) => void) | null
    defaultProv?: () => V,
    icon?: string,
    treeText?: ((vp: V) => string);
    /**
     * if true, the children (varp outputs) will not be shown in the tree view
     */
    hideTreeChildren?: boolean,
    /**
     * allows varps types to customize their delete behavior.
     * this function will run just before the varp is actually deleted.
     */
    delete?: (vp: V) => void;
}

export type VarpTypeMap = {
    [varpTypeKey in Latest.VarProviders.VarProvider['type']]: VarpTypeMapItem<Latest.VarProviders.VarProvider & { type: varpTypeKey }>;
};

const varpTypeMapStrict: VarpTypeMap = {
    calendar: {
        defaultProv: () => {
            const defaultProv: Latest.VarProviders.Calendar.Provider = {
                attrs: {
                    location: {
                        id: '',
                    },
                    udfCalendarEvents: [],
                    varPrefix: 'cal',
                },
                id: uuidV4(),
                outputs: [],
                type: 'calendar',
            };
            return defaultProv;
        },
        description: messages.mes.varProviders.calendar.desc,
        icon: 'mdi-calendar',
        tab: () => import('src/applet/design/varps/calendar/Calendar.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.calendar.title,
        treeText: cfg => cfg.attrs.varPrefix,
        type: 'calendar',
        validator: vp => {
            const validationErrs = messages.mes.varProviders.calendar.validationErrs;
            if (!vp.attrs.varPrefix) {
                throw new Error(validationErrs.noVarPrefix);
            }
            for (const event of vp.attrs.udfCalendarEvents) {
                if (!event.varName) {
                    throw new Error(validationErrs.noOutputVar);
                }
            }
        },
    },
    comparative: {
        defaultProv: () => ({
            attrs: {
                assignments: [],
                initialAsFallback: true,
                initialState: {
                    initialVal: null,
                    status: 'pending',
                    type: 'null',
                },
                targetVar: '',
            },
            id: uuidV4(),
            inputs: [],
            outputs: [],
            type: 'comparative',
        }),
        description: messages.mes.varProviders.comparative.desc,
        hideTreeChildren: true,
        icon: 'mdi-scale-balance',
        tab: () => import('src/applet/design/varps/comparative/Comparative.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.comparative.title,
        treeText: cfg => cfg.attrs.targetVar || messages.mes.varProviders.comparative.title,
        type: 'comparative',
        validator: vp => {
            const validationErrs = messages.mes.varProviders.comparative.validationErrs;
            if (!vp.attrs.targetVar) {
                throw new Error(validationErrs.assignment.varName);
            }
            if (vp.attrs.assignments.length === 0) {
                throw new Error(validationErrs.noAssignments);
            }
            for (let aIdx = 0; aIdx < vp.attrs.assignments.length; aIdx++) {
                try {
                    const assignment = vp.attrs.assignments[aIdx];
                    if (assignment.inputType === 'var' && !assignment.inputVar) {
                        throw new Error(validationErrs.assignment.inputVar);
                    }

                    if (assignment.comparisons.length === 0) {
                        throw new Error(validationErrs.assignment.noComparisons);
                    }
                    validateComparisons(assignment.comparisons);
                } catch (err) {
                    throw new CausalError(validationErrs.assignment.invalid(aIdx), err);
                }
            }
        },
    },
    createMaterial: {
        defaultProv: () => {
            const defaultProv: Latest.VarProviders.CreateMaterial.Provider = {
                attrs: {
                    allowExistingMaterial: true,
                    conditional: {
                        boolOperation: 'and',
                        comparisons: [],
                    },
                    location: {
                        deployParamId: 'Location',
                        deployParamKind: DeployParamKind.location,
                    },
                    materialIdVar: '',
                    materialModels: [],
                },
                id: uuidV4(),
                inputs: [],
                outputs: [],
                type: 'createMaterial',
            };
            return defaultProv;
        },
        description: messages.mes.varProviders.createMaterial.desc,
        icon: 'mdi-shape-square-plus',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/varps/create-material/CreateMaterial.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.createMaterial.title,
        type: 'createMaterial',
        validator: vp => {
            if (!vp.attrs.location) {
                throw new Error(messages.mes.varProviders.createMaterial.validationErrs.required.locationId);
            }
            if (!vp.attrs.conditional.comparisons.length) {
                throw new Error(messages.mes.varProviders.createMaterial.validationErrs.required.condition);
            }
            if (!vp.attrs.materialModels.length) {
                throw new Error(messages.mes.varProviders.createMaterial.validationErrs.required.materialModelList);
            }
            vp.attrs.materialModels.forEach(model => {
                if (!model.materialModelId) {
                    throw new Error(messages.mes.varProviders.createMaterial.validationErrs.required.materialModelId);
                }
                for (const udfKey in model.udfs) {
                    const udf = model.udfs[udfKey];
                    if (udf.required && !varOrConstIsSet(udf.val)) {
                        throw new Error(messages.mes.varProviders.createMaterial.validationErrs.required.udf(udf.name));
                    }
                }
                if (model.snCfg.type === 'direct') {
                    if (!varOrConstIsSet(model.snCfg.serialNumber)) {
                        throw new Error(messages.mes.varProviders.createMaterial.validationErrs.required.serialNumber);
                    }
                } else {
                    for (const inputKey in model.snCfg.inputs) {
                        if (!varOrConstIsSet(model.snCfg.inputs[inputKey])) {
                            throw new Error(messages.mes.varProviders.createMaterial.validationErrs.required.snInput(inputKey));
                        }
                    }
                }

                if (model.conditional) {
                    try {
                        validateComparisons(model.conditional.comparisons);
                    } catch (err) {
                        throw new CausalError('Material Model Condition is invalid', err);
                    }
                }
            });
            if (vp.attrs.conditional) {
                try {
                    validateComparisons(vp.attrs.conditional.comparisons);
                } catch (err) {
                    throw new CausalError('Material Creation Condition is invalid', err);
                }
            }
        },
    },
    formatted: {
        defaultProv: () => ({
            attrs: {
                inputVars: [],
                outputVar: '',
                stringTemplate: '',
                usingErrors: false,
            },
            id: uuidV4(),
            outputs: [],
            type: 'formatted',
        }),
        description: messages.mes.varProviders.formatted.desc,
        hideTreeChildren: true,
        icon: 'mdi-format-font',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/varps/Formatted.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.formatted.title,
        treeText: cfg => cfg.attrs.outputVar || messages.mes.varProviders.formatted.title,
        type: 'formatted',
        validator: vp => {
            if (!vp.attrs.outputVar) {
                throw new Error(messages.mes.varProviders.formatted.err.outputVar);
            }
        },
    },
    local: {
        disableAdd: true,
        icon: 'mdi-pin',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/varps/local/Local.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.local.title,
        type: 'local',
        validator: vp => {
            for (const varItem of vp.attrs.vars) {
                if (!varItem.name) {
                    throw new Error(messages.mes.varProviders.local.err);
                }
            }
        },
    },
    locking: {
        defaultProv: () => ({
            attrs: {
                lockingVar: '',
                vars: [],
            },
            id: uuidV4(),
            outputs: [],
            type: 'locking',
        }),
        description: messages.mes.varProviders.locking.desc,
        icon: 'mdi-lock',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/varps/Locking.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.locking.title,
        type: 'locking',
        validator: null,
    },
    macroTarget: {
        disableAdd: true,
        text: messages.mes.varProviders.macroTarget.title,
        type: 'macroTarget',
        validator: null,
    },
    materialId: {
        defaultProv: () => ({
            attrs: {
                inputVar: '',
                outputVar: '',
                udfOutputs: [],
            },
            id: uuidV4(),
            inputs: [],
            outputs: [],
            type: 'materialId',
        }),
        description: messages.mes.varProviders.materialId.desc,
        icon: 'mdi-qrcode',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/varps/MaterialId.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.materialId.title,
        type: 'materialId',
        validator: vp => {
            if (!vp.attrs.inputVar) {
                throw new Error(messages.mes.varProviders.materialId.errors.noInputVar);
            }
            if (!vp.attrs.outputVar) {
                throw new Error(messages.mes.varProviders.materialId.errors.noOutputVar);
            }

            for (const udfOutput of vp.attrs.udfOutputs) {
                if (!udfOutput.outputVar) {
                    throw new Error(messages.mes.varProviders.materialId.errors.noOutputUdfVar);
                }
            }
        },
    },
    mqttPublish: {
        defaultProv: () => ({
            attrs: {
                condition: {
                    boolOperation: 'and',
                    comparisons: [],
                },
                payload: {
                    type: 'const',
                    val: '',
                },
                payloadType: 'string',
                publishResultVar: '',
                qos: 0,
                retain: false,
                topic: {
                    type: 'const',
                    val: '',
                },
            },
            id: uuidV4(),
            inputs: [],
            outputs: [],
            type: 'mqttPublish',
        }),
        description: messages.mes.varProviders.mqttPublish.desc,
        icon: 'mdi-cloud-upload',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/varps/mqtt-publish/MqttPublish.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.mqttPublish.title,
        type: 'mqttPublish',
        validator: vp => {
            const attrsSchema = varpTypes.mqttPublish.shape.attrs;
            wrapAndThrow(() => attrsSchema.shape.payload.parse(vp.attrs.payload), messages.mes.varProviders.mqttPublish.errors.payload);
            wrapAndThrow(() => attrsSchema.shape.topic.parse(vp.attrs.topic), messages.mes.varProviders.mqttPublish.errors.topic);
            wrapAndThrow(() => attrsSchema.shape.condition.parse(vp.attrs.condition), messages.mes.varProviders.mqttPublish.errors.condition);
            wrapAndThrow(() => varpTypes.mqttPublish.parse(vp), messages.mes.varProviders.mqttPublish.errors._other);
        },
    },
    mqttSubscribe: {
        defaultProv: () => ({
            attrs: {
                parseConfig: {
                    type: 'str',
                    var: '',
                },
                qos: 0,
                topic: {
                    type: 'const',
                    val: '',
                },
                tsVar: '',
            },
            id: uuidV4(),
            inputs: [],
            outputs: [],
            type: 'mqttSubscribe',
        }),
        description: messages.mes.varProviders.mqttSubscribe.desc,
        icon: 'mdi-cloud-download',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/varps/mqtt-subscribe/MqttSubscribe.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.mqttSubscribe.title,
        type: 'mqttSubscribe',
        validator: vp => {
            const attrsSchema = varpTypes.mqttSubscribe.shape.attrs;
            const topicSchema = attrsSchema.shape.topic;
            wrapAndThrow(() => topicSchema.parse(vp.attrs.topic), messages.mes.varProviders.mqttSubscribe.errors.topic);
            wrapAndThrow(() => attrsSchema.shape.parseConfig.parse(vp.attrs.parseConfig), messages.mes.varProviders.mqttSubscribe.errors.payloadVar);
            wrapAndThrow(() => varpTypes.mqttSubscribe.parse(vp), messages.mes.varProviders.mqttSubscribe.errors._other);
        },
    },
    page: {
        disableAdd: true,
        text: 'Page Provider',
        type: 'page',
        validator: null,
    },
    paged: {
        disableAdd: true,
        text: 'Paged Provider',
        type: 'paged',
        validator: null,
    },
    process: {
        defaultProv: () => ({
            attrs: {
                dataCollectionVars: {},
                location: {
                    deployParamId: 'Location',
                    deployParamKind: DeployParamKind.location,
                },
                materialIdVar: '',
                materialProcessStateIdVar: '',
                materialProcessStateUpdateVar: '',
                params: {},
                processRevId: '',
                stateVars: {
                    bad: '',
                    good: '',
                    required: '',
                    specId: '',
                    specName: '',
                    status: '',
                    validationError: '',
                },
                trigger: {
                    boolOperation: 'and',
                    comparisons: [],
                },
            },
            id: uuidV4(),
            inputs: [],
            outputs: [],
            type: 'process',
        }),
        description: messages.mes.varProviders.process.desc,
        disableAdd: false,
        icon: '$argo-data-element',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/varps/Process.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.process.title,
        type: 'process',
        validator: vp => {
            if (!vp.attrs.materialIdVar) {
                throw new Error(messages.mes.varProviders.process.err.noMaterialId);
            }
            if (!vp.attrs.processRevId) {
                throw new Error(messages.mes.varProviders.process.err.noProcess);
            }
            const dcPdeIds = Object.keys(vp.attrs.dataCollectionVars);
            if (dcPdeIds.length > 0 && !vp.attrs.trigger) {
                throw new Error(messages.mes.varProviders.process.err.trig);
            }

            for (const dcPdeId of dcPdeIds) {
                if (!vp.attrs.dataCollectionVars[dcPdeId]) {
                    throw new Error(messages.mes.varProviders.process.err.dcVars);
                }
            }

            validateComparisons(vp.attrs.trigger.comparisons);
        },
    },
    script: {
        defaultProv: () => ({
            attrs: {
                executeCfg: {
                    includedVars: [],
                    outputVars: [],
                    type: 'default',
                },
                includedVars: [],
                outputVars: [
                    {
                        initialVal: '',
                        name: 'scriptVar1',
                        type: 'str',
                    },
                ],
                scriptId: '',
            },
            id: uuidV4(),
            inputs: [],
            outputs: [],
            type: 'script',
        }),
        delete: vp => {
            const entityData = accessor.entityAsType('appletDesignVersion');
            if (!entityData) {
                return;
            }
            entityData.config.scripts = entityData.config.scripts.filter(s => s.id !== vp.attrs.scriptId);
            accessor.setPageEntity({ entity: entityData, type: 'appletDesignVersion' });
        },
        description: messages.mes.varProviders.script.desc,
        icon: 'mdi-script-text',
        tab: () => import('src/applet/design/varps/script/Script.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.script.title,
        treeText: vp => vp.label || messages.mes.varProviders.script.title,
        type: 'script',
        validator: v => {
            if (!v.attrs.scriptId) {
                throw new Error(messages.mes.varProviders.script.validationErrs.scriptId);
            }
            if (v.attrs.executeCfg.type === 'custom') {
                if (!v.attrs.executeCfg.condition.comparisons.length) {
                    throw new Error(messages.mes.varProviders.script.validationErrs.executeCondition);
                }
                validateComparisons(v.attrs.executeCfg.condition.comparisons);
            }
            if (v.attrs.executeCfg.type === 'default') {
                if (!v.attrs.executeCfg.outputVars.length) {
                    throw new Error(messages.mes.varProviders.script.validationErrs.noOutputs);
                }
                if (!v.attrs.executeCfg.includedVars.length || !v.attrs.executeCfg.includedVars.find(includedVar => includedVar.tracked)) {
                    throw new Error(messages.mes.varProviders.script.validationErrs.noInputs);
                }
            }
            for (const output of v.outputs) {
                if (v.inputs.includes(output)) {
                    throw new Error(messages.mes.varProviders.script.validationErrs.circularReference);
                }
            }

        },
    },
    slice: {
        defaultProv: () => ({
            attrs: {
                inputVar: '',
                outputVar: '',
                sliceEnd: 1,
                sliceStart: 0,
            },
            id: uuidV4(),
            outputs: [],
            type: 'slice',
        }),
        description: messages.mes.varProviders.slice.desc,
        hideTreeChildren: true,
        icon: 'mdi-content-cut',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/varps/Slice.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.slice.title,
        treeText: vp => vp.attrs.outputVar,
        type: 'slice',
        validator: vp => {
            if (vp.attrs.sliceEnd !== undefined && vp.attrs.sliceEnd <= vp.attrs.sliceStart) {
                throw new Error(messages.mes.varProviders.slice.minEnd);
            }
        },
    },
    sparkplug: {
        defaultProv: () => ({
            attrs: {
                nodeCfg: {
                    deviceId: {
                        type: 'const',
                        val: '',
                    },
                    groupId: '',
                    nodeId: '',
                    type: 'manual',
                    version: '',
                },
                vars: [],
            },
            id: uuidV4(),
            outputs: [],
            type: 'sparkplug',
        }),
        description: messages.mes.varProviders.sparkplugConsumer.desc,
        icon: 'mdi-flash',
        tab: () => import('src/applet/design/varps/sparkplug/Sparkplug.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.sparkplugConsumer.title,
        treeText: vp => {
            let deviceId = '';
            const nodeCfg = vp.attrs.nodeCfg;
            if (!('deployParamId' in nodeCfg)) {
                if (typeof nodeCfg.deviceId === 'string') {
                    deviceId = nodeCfg.deviceId;
                } else if (nodeCfg.deviceId.type === 'const') {
                    deviceId = nodeCfg.deviceId.val;
                }
            }
            return deviceId ? `${deviceId} (Sparkplug)` : '';
        },
        type: 'sparkplug',
        validator: vp => {
            const nodeCfg = vp.attrs.nodeCfg;
            if (!('deployParamId' in nodeCfg)) {
                if ([
                    nodeCfg.version,
                    nodeCfg.nodeId,
                    nodeCfg.groupId,
                ].some(x => !x)) {
                    throw new Error('Target sparkplug device node cannot be blank');
                }
                const deviceId = nodeCfg.deviceId;
                if (
                    !deviceId ||
                    (typeof deviceId === 'object' && deviceId.type === 'const' && !deviceId.val) ||
                    (typeof deviceId === 'object' && deviceId.type === 'var' && !deviceId.var)
                ) {
                    throw new Error('Target sparkplug device cannot be left blank');
                }
            }
            for (const varCfg of vp.attrs.vars) {
                let expr: MetricExpr;
                try {
                    expr = getMetricExpr(varCfg.metric);
                } catch (err) {
                    console.error('Metric expression error', err);
                    throw new Error(`Metric expression for variable "${varCfg.varName}" is invalid`);
                }

                const checkResult = validateSparkplugMetricExpr(expr);
                if (checkResult) {
                    throw new Error(`Metric expression for variable "${varCfg.varName}" is invalid: ${checkResult}`);
                }
            }
        },
    },
    sparkplugDeviceWrite: {
        defaultProv: () => ({
            attrs: {
                errorVar: '',
                metrics: [],
                nodeCfg: {
                    deviceId: {
                        type: 'const',
                        val: '',
                    },
                    groupId: '',
                    nodeId: '',
                    type: 'manual',
                    version: '',
                },
                resultVar: '',
                trigger: {},
                varsToResetOnSend: [],
            },
            id: uuidV4(),
            inputs: [],
            outputs: [],
            type: 'sparkplugDeviceWrite',
        }),
        description: messages.mes.varProviders.sparkplugCommander.desc,
        icon: 'mdi-message-flash',
        tab: () => import('src/applet/design/varps/sparkplugDeviceWrite/SparkplugDeviceWrite.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.sparkplugCommander.title,
        treeText: vp => !('deployParamId' in vp.attrs.nodeCfg) && typeof vp.attrs.nodeCfg.deviceId === 'object' && vp.attrs.nodeCfg.deviceId.type === 'const' ? `${vp.attrs.nodeCfg.deviceId.val} (Sparkplug Write)` : '',
        type: 'sparkplugDeviceWrite',
        validator: vp => {
            const nodeCfg = vp.attrs.nodeCfg;
            if (!('deployParamId' in nodeCfg)) {
                if ([
                    nodeCfg.version,
                    nodeCfg.nodeId,
                    nodeCfg.groupId,
                ].some(x => !x)) {
                    throw new Error('Target sparkplug device node cannot be blank');
                }
                const deviceId = nodeCfg.deviceId;
                if (
                    !deviceId ||
                    (typeof deviceId === 'object' && deviceId.type === 'const' && !deviceId.val) ||
                    (typeof deviceId === 'object' && deviceId.type === 'var' && !deviceId.var)
                ) {
                    throw new Error('Target sparkplug device cannot be left blank');
                }
            }
            for (const metricCfg of vp.attrs.metrics) {
                let expr: MetricExpr;
                try {
                    expr = getMetricExpr(metricCfg.metricExpr);
                } catch (err) {
                    console.error('Metric expression error', err);
                    throw new Error(`Metric expression for variable "${metricCfg.varSource}" is invalid`);
                }

                const checkResult = validateSparkplugDeviceWriteMetricExpr(expr);
                if (checkResult) {
                    throw new Error(`Metric expression for variable "${metricCfg.varSource}" is invalid: ${checkResult}`);
                }
            }

            const localVars = accessor.appletDesign.localVariables;
            for (const resetVar of vp.attrs.varsToResetOnSend) {
                if (!localVars.includes(resetVar)) {
                    throw new Error(messages.mes.errors.appConfig.nonexistentVarVarp({
                        providerName: vp.label,
                        providerTitle: vp.type,
                        varName: resetVar,
                    }));
                }
            }

            validateComparisons(vp.attrs.trigger.conditional?.comparisons || []);
        },
    },
    tcpClient: {
        defaultProv: () => ({
            attrs: {
                messages: [],
                serverHost: '',
                serverPort: 1234,
                terminator: 'crlf',
                treatDisconnectAsError: true,
                varForReceivedData: '',
            },
            id: uuidV4(),
            outputs: [],
            type: 'tcpClient',
        }),
        description: 'Send and receive data with a TCP Server',
        icon: 'mdi-hexadecimal',
        tab: () => import('src/applet/design/varps/tcp-client/TcpClient.tab.var.vue').then(m => m.default),
        text: 'TCP Client',
        type: 'tcpClient',
        validator: vp => {
            wrapAndThrow(() => varpTypes.tcpClient.parse(vp), 'TCP Client provider is invalid');
        },
    },
    tcpServer: {
        defaultProv: () => ({
            attrs: {
                messagesToClients: [],
                serverHost: '',
                serverPort: 4321,
                terminator: 'crlf',
                varForConnectedClientsNum: '',
                varForReceivedData: '',
            },
            id: uuidV4(),
            outputs: [],
            type: 'tcpServer',
        }),
        description: 'Send and receive data with TCP Clients',
        icon: 'mdi-hexadecimal',
        tab: () => import('src/applet/design/varps/tcp-server/TcpServer.tab.var.vue').then(m => m.default),
        text: 'TCP Server',
        type: 'tcpServer',
        validator: vp => {
            wrapAndThrow(() => varpTypes.tcpServer.parse(vp), 'TCP Server provider is invalid');
        },
    },
    timer: {
        defaultProv: () => ({
            attrs: {
                dir: 'down',
                maxVal: { type: 'const', val: 60 },
                minVal: { type: 'const', val: 0 },
                outputVar: '',
                pauseCond: null,
                resetCond: {
                    boolOperation: 'and',
                    comparisons: [],
                },
            },
            id: uuidV4(),
            outputs: [],
            type: 'timer',
        }),
        description: messages.mes.varProviders.timer.desc,
        icon: 'mdi-timer',
        tab: () => import('src/applet/design/varps/timer/Timer.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.timer.title,
        type: 'timer',
        validator: vp => {
            try {
                validateComparisons(vp.attrs.resetCond.comparisons);
            } catch (err) {
                throw new CausalError('Reset condition is invalid', err);
            }

            try {
                validateComparisons(vp.attrs.pauseCond?.comparisons || []);
            } catch (err) {
                throw new CausalError('Pause condition is invalid', err);
            }
        },
    },
    udpReceive: {
        defaultProv: () => ({
            attrs: {
                encoding: 'utf8',
                hostInterface: '',
                payloadVar: '',
                port: 1234,
                tsVar: '',
            },
            id: uuidV4(),
            inputs: [],
            outputs: [],
            type: 'udpReceive',
        }),
        description: messages.mes.varProviders.udpReceive.desc,
        icon: 'mdi-hexadecimal',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/varps/udp-receive/UdpReceive.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.udpReceive.title,
        type: 'udpReceive',
        validator: vp => {
            const attrsSchema = varpTypes.udpReceive.shape.attrs;
            wrapAndThrow(() => attrsSchema.shape.port.parse(vp.attrs.port), messages.mes.varProviders.udpReceive.errors.port);
            wrapAndThrow(() => attrsSchema.shape.payloadVar.parse(vp.attrs.payloadVar), messages.mes.varProviders.udpReceive.errors.payloadVar);

            wrapAndThrow(() => varpTypes.udpReceive.parse(vp), messages.mes.varProviders.udpReceive.errors._other);
        },
    },
    udpSend: {
        defaultProv: () => ({
            attrs: {
                condition: {
                    boolOperation: 'and',
                    comparisons: [],
                },
                host: { type: 'const', val: '' },
                message: { type: 'const', val: '' },
                port: { type: 'const', val: 1234 },
                sentTsVar: '',
            },
            id: uuidV4(),
            inputs: [],
            outputs: [],
            type: 'udpSend',
        }),
        description: messages.mes.varProviders.udpSend.desc,
        icon: 'mdi-hexadecimal',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/varps/udp-send/UdpSend.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.udpSend.title,
        type: 'udpSend',
        validator: vp => {
            const attrsSchema = varpTypes.udpSend.shape.attrs;
            wrapAndThrow(() => attrsSchema.shape.host.parse(vp.attrs.host), messages.mes.varProviders.udpSend.errors.host);
            wrapAndThrow(() => attrsSchema.shape.port.parse(vp.attrs.port), messages.mes.varProviders.udpSend.errors.port);
            wrapAndThrow(() => attrsSchema.shape.condition.parse(vp.attrs.condition), messages.mes.varProviders.udpSend.errors.condition);

            wrapAndThrow(() => varpTypes.udpSend.parse(vp), messages.mes.varProviders.udpSend.errors._other);
        },
    },
    varStatus: {
        defaultProv: () => ({
            attrs: {
                inputVar: '',
                outputVar: '',
            },
            id: uuidV4(),
            inputs: [],
            outputs: [],
            type: 'varStatus',
        }),
        description: messages.mes.varProviders.varStatus.desc,
        icon: 'mdi-magnify-close',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/varps/VarStatus.tab.var.vue').then(m => m.default),
        text: messages.mes.varProviders.varStatus.title,
        type: 'varStatus',
        validator: vp => {
            if (!vp.attrs.inputVar || !vp.attrs.outputVar) {
                throw new Error(messages.invalidConfig);
            }
        },
    },
};

export const varpTypeMap = varpTypeMapStrict as unknown as {
    [varpTypeKey in Latest.VarProviders.VarProvider['type']]: VarpTypeMapItem;
};

export const varpTypeMapItems = Object.keys(varpTypeMap).map(k => varpTypeMap[k as Latest.VarProviders.VarProviderType]);

export default varpTypeMap;
