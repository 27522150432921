import { varConditionSchema_v3 as varConditionSchema } from '@redviking/argonaut-util/types/mes/applet-designs/v3/var-providers/conditional.v3.zod';
import {
    varOrConstSchema_v3 as varOrConstSchema,
    varpConfigBase_v3 as varpConfigBase,
} from '@redviking/argonaut-util/types/mes/applet-designs/v3/var-providers/var-provider.v3.zod';
import { z } from 'zod';
import { optionalVarNameSchema } from '../../var.zod';

export const tcpClientProviderSchema = varpConfigBase.extend({
    type: z.literal('tcpClient'),
    attrs: z.object({
        serverHost: z.string().min(1),
        serverPort: z.number().min(1),
        messages: z.array(z.object({
            content: varOrConstSchema(z.string().min(1)),
            condition: varConditionSchema.refine(val => {
                return val.comparisons.length > 0;
            }, { message: 'condition must have at least one comparison' }),
        })),
        terminator: z.union([
            z.literal('none'),
            z.literal('crlf'),
            z.literal('lf'),
            z.literal('cr'),
        ]).default('crlf'),
        varForReceivedData: optionalVarNameSchema,
        treatDisconnectAsError: z.boolean().default(true),
    }),
});
export interface TcpClientProviderConfig extends z.infer<typeof tcpClientProviderSchema> {}
