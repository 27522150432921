import { mediaObjectSchema } from '../../../../../src/media.util';
import { cellCfgAttrsBaseSchema_v3, cellCfgBaseSchema_v3 } from '../../v3/cell-types/cell-base.v3.zod';
import z from 'zod';
import { varNameSchema } from '../../var.zod';

// FUTURE: if other applet things end up using this, move it.
const appletMediaItemSchema = z.discriminatedUnion('type', [
    ...mediaObjectSchema.options,
    z.object({
        type: z.literal('varForExternal'),
        var: varNameSchema,
    }),
]);

// FUTURE: if other applet things end up using this, move it.
export type AppletMediaItem = z.infer<typeof appletMediaItemSchema>;

export const mediaCellSchema_v5 = cellCfgBaseSchema_v3.extend({
    type: z.literal('media'),
    attrs: cellCfgAttrsBaseSchema_v3.extend({
        items: z.array(z.object({
            id: z.string(),
            media: appletMediaItemSchema,
            /** seconds */
            timeout: z.number().optional(),
            caption: z.string().optional(),
            /** name of the variable that will set whether the image is shown or not */
            variable: z.string().optional(),
        })),
    }),
});
