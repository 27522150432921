import type { Route, RouteConfig } from 'vue-router';
import { entityDetailRoute } from '@redviking/argonaut-core-ui/src/components/EntityDetail/entity-route-util';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import {
    getAppletDesignVersion,
    saveAppletDesignVersion,
} from './applet.entity';
import { generalTabRoute, jsonTabRoute, screensTabRoute, varsTabRoute } from './util/consts';


export const appletDesignRoutes: RouteConfig[] = [
    {
        path: '',
        name: 'appletDesignList',
        component: () => import(/* webpackChunkName: "appletDesign" */ './AppletDesignList.view.vue'),
        meta: {
            pageBannerTitle: 'Applet Designs',
        },
    },
    entityDetailRoute<'appletDesignVersion'>({
        path: ':appletDesignId/version/:appletDesignVersionId',
        name: 'appletDesignVersion',
        component: () => import(/* webpackChunkName: "appletDesign" */ './AppletDesignVersion.view.vue'),
        meta: {
            canEdit: () => {
                const adv = accessor.entityAsType('appletDesignVersion');
                return adv?.status === 'staged';
            },
            entityType: 'appletDesignVersion',
            getEntity: getAppletDesignVersion,
            saveEntity: saveAppletDesignVersion,
            pageBannerTitle: (route: Route) => {
                const mode = route.query?.mode || '';
                const cfgName = accessor.entityAsType('appletDesignVersion')?.design?.name || '';
                const cfgVerNum = accessor.entityAsType('appletDesignVersion')?.versionNumber || null;
                if (mode === 'create') {
                    return 'New Applet Design';
                }
                return `${cfgName} v${cfgVerNum}`;
            },
            returnRoute: { name: 'appletDesignList' },
        },
        redirect: to => ({ name: generalTabRoute, params: to.params }),
        children: [
            {
                path: 'general',
                name: generalTabRoute,
                component: () => import(/* webpackChunkName: "appletDesign" */ './General.tab.view.vue'),
                meta: {
                    tab: {
                        label: 'General',
                        icon: 'mdi-note-text',
                    },
                },
            },
            {
                path: 'vars',
                name: varsTabRoute,
                components: {
                    default: () => import(/* webpackChunkName: "appletDesign" */ './varps/Var.tab.view.vue'),
                },
                meta: {
                    tab: {
                        label: 'Providers',
                        icon: 'mdi-variable-box',
                    },
                },
            },
            {
                path: 'screens',
                name: screensTabRoute,
                components: {
                    default: () => import(/* webpackChunkName: "appletDesign" */ './screens/Screen.tab.view.vue'), // a component for the settings page content
                },
                meta: {
                    tab: {
                        label: 'Screens',
                        icon: 'mdi-monitor-dashboard',
                    },
                },
            },
            {
                path: 'json',
                name: jsonTabRoute,
                components: {
                    default: () => import(/* webpackChunkName: "appletDesign" */ './Json.tab.view.vue'),
                },
                meta: {
                    tab: {
                        label: 'JSON',
                        icon: 'mdi-code-braces',
                    },
                },
            },
        ],
    }),
];
